<template>
 <div id="ConfgProduct">
 <Navigation/>
  <ConfgProduct />
  <partners />
  <Footer />
 </div>
</template>

<script>

import Partners from "../../home/components/Partners.vue";
import Footer from "../../home/components/Footer.vue";
import ConfgProduct from "../components/ConfgProduct.vue";
import Navigation from "../../home/components/Navigation.vue";

export default {
 components: {
    Partners,
    Footer,
    ConfgProduct,
    Navigation
},
};
</script>

<style lang="sass" scoped></style>
