<template>
 <div id="ConfgProduct">
  <b-container>
   <b-row>
    <b-col>
     <div class="HeaderConfigProduct">
      <h4><i class="fa fa-shopping-cart"></i>Configure Your Product</h4>
     </div>
    </b-col>
   </b-row>
  </b-container>
  <b-container>
   <b-row>
    <b-col lg="8">
     <div class="mobproductdetails">
      <div class="headerDetails">
       <h4><i class="fa fa-info"></i> Product Details</h4>
      </div>
      <div class="product-info">
       <div class="product-title">
        <h3>Linux Shared Hosting Plans - (Basic)</h3>
       </div>
       <div class="details-list">
        <p>2 GB SSD Storage</p>
        <p>1 Website Hosting</p>
        <p>2 MySQL Database</p>
        <p>No Subdomains</p>
        <p>No Addon Domains</p>
        <p>1 cPanel</p>
        <p>5 Email Accounts</p>
        <p>50 GB Bandwidth</p>
        <p class="free-ssl-service">
         <i class="fa fa-lock"></i> Free SSL Certificate
        </p>
        <p>One Click Installation (Softaculous)</p>
       </div>
       <div class="moretext">
        <p>1 Core CPU</p>
        <p>1GB RAM</p>
        <p>
         50 Concurrent connections
         <span class="tooltip2">
          <i
           title="Simultaneously 50 concurrent sessions (EP - entry process) with double
          number of nproc"
           class="fa fa-question-circle"
          ></i>
         </span>
        </p>
        <p>
         1024KB/sec IO throughput
         <span class="tooltip2">
          <i
           title="IO throughput - combines both read & write operations "
           class="fa fa-question-circle"
          ></i>
         </span>
        </p>
       </div>
      </div>
      <div class="inputBillingcycle">
       <div class="headerBillingcycle">
        <h4><i class="fa fa-receipt"></i> Choose Billing Cycle</h4>
       </div>
       <div class="new-addon">
        <b-container>
         <b-row cols="1" cols-sm="2" cols-md="3" cols-lg="4">
          <b-col>
           <div class="order-details-now order-details">
            <div class="order-details-list">
             <p>1 Month</p>
             <h1>$1.33</h1>
             <span>/month</span>
            </div>
           </div>
          </b-col>
          <b-col>
           <div class="order-details">
            <div class="discount-main">
             <div class="old-price">
              <strike>$1.33/month</strike>
             </div>
             <div class="discount-tag">
              <div class="discount-size">
               <p>save 17%</p>
              </div>
             </div>
            </div>
            <div class="order-details-list">
             <p>1 Month</p>
             <h1>$1.33</h1>
             <span>/month</span>
            </div>
           </div>
          </b-col>
          <b-col>
           <div class="order-details">
            <div class="discount-main">
             <div class="old-price">
              <strike>$1.33/month</strike>
             </div>
             <div class="discount-tag">
              <div class="discount-size">
               <p>save 17%</p>
              </div>
             </div>
            </div>
            <div class="order-details-list">
             <p>1 Month</p>
             <h1>$1.33</h1>
             <span>/month</span>
            </div>
           </div>
          </b-col>
          <b-col>
           <div class="order-details">
            <div class="discount-main">
             <div class="old-price">
              <strike>$1.33/month</strike>
             </div>
             <div class="discount-tag">
              <div class="discount-size">
               <p>save 17%</p>
              </div>
             </div>
            </div>
            <div class="order-details-list">
             <p>1 Month</p>
             <h1>$1.33</h1>
             <span>/month</span>
            </div>
           </div>
          </b-col>
         </b-row>
        </b-container>
       </div>
      </div>
      <div class="PanelAddons">
       <div class="headerPanelAddons">
        <h4><i class="fas fa-check"></i>Available Addons</h4>
       </div>
       <div class="container-section-panal-addon">
        <div class="panel-body">
         <header class="radio-option">
          <label>
           <div id="ins" class="radio-round">
            <input type="radio" />
            <ins v-on:click="showOption" class="iCheck-helper"></ins>
           </div>
           <span>Backup Service 2 GB 1 Year</span>
          </label>
          <div class="img">
           <img src="../assets/Icons8_flat_data_backup.svg.png" alt="" />
          </div>
          <p>2 GB Monthly Backup</p>
         </header>
        </div>
        <div class="panel-price">$1.61 USD Annually</div>
        <div class="panel-add">
         <i class="fas fa-shopping-cart"></i> Added to Cart (Remove)
        </div>
       </div>
      </div>
     </div>
    </b-col>
    <b-col lg="4">
     <div class="scrollingPanelContainer">
      <div class="title-order-summary">
       <h5><i class="fa fa-globe"></i> Your Order Summary</h5>
      </div>
      <div class="order-summary">
       <div class="title">
        <h4>Linux Shared Hosting Plans</h4>
       </div>
       <div class="product-heading">
        <span>Basic</span>
        <span>$15.01 </span>
       </div>
       <hr />
       <div class="summary-totals">
        <div class="sectiion-list">
         <span>Setup Fees :</span>
         <span>$0.00</span>
        </div>
        <div class="sectiion-list">
         <span>Triennially :</span> <span>$15.01</span>
        </div>
        <div class="sectiion-list">
         <span>Taxes (VAT) @ 10.00%:</span> <span> $1.50 </span>
        </div>
       </div>
       <hr />
       <div class="total-due-today">
        <span> Total Amount</span>
        <span>$16.51</span>
       </div>
      </div>
      <div class="btnDomainContinue">
       <button type="submit">
        <router-link class="link" to="ConfgDomains">Continue</router-link>
        <i class="fa fa-arrow-circle-right"></i>
       </button>
      </div>
     </div>
    </b-col>
   </b-row>
  </b-container>
 </div>
</template>

<script>
export default {
 methods: {
  showOption: function () {
   var ins = document.getElementById("ins");
   if (ins.style.display == "none") {
    ins.style.background = "#FFF";
   } else {
    ins.style.background = "#06155a";
   }
  },
 },
};
</script>

<style lang="scss" scoped>
@import "@/styles/colors/_main-colors.scss";
@import "@/styles/fonts/_main-fonts.scss";
#ConfgProduct {
 padding: 150px 0;

 @mixin style-headers {
  padding: 10px 20px;
  background: map-get($branding, them-color-option-tow);
  border-radius: 2px;
 }
 @mixin style-text-header {
  color: blanchedalmond;
  font-size: 17px;
  font-weight: 400;
  margin: 0;
 }
 @mixin style-icon-befor-header {
  margin-right: 5px;
  font-size: 19px;
 }
 @mixin style-list {
  font-size: 15px;
  border-bottom: 1px solid #f1ebeb;
  padding: 10px;
  margin-bottom: 0;
  color: #5b5858;
 }
 @mixin product-info {
  box-shadow: 0 0 13px 0 #00000042;
  margin-top: 10px;
  padding: 20px 15px;
  background-color: #fafafa;
  border-radius: 2px;
  background-color: #fff;
  border: 1px solid #efefef;
  margin: 8px 0 10px 0;
 }
 @mixin text-in-span-summary {
  font-size: 13px;
  color: #000;
  font-weight: 600;
 }
 .HeaderConfigProduct {
  @include style-headers;
  h4 {
   @include style-text-header;
   .fa-shopping-cart {
    @include style-icon-befor-header;
   }
  }
 }
 .mobproductdetails {
  .headerDetails {
   @include style-headers;
   margin-top: 5px;
   padding: 10px 31px;
   h4 {
    @include style-text-header;
    font-size: 15px;
    .fa-info {
     @include style-icon-befor-header;
    }
   }
  }

  .product-info {
   @include product-info;

   .product-title {
    h3 {
     font-size: 23px;
     color: map-get($color-texts, color-font-option);
     padding-top: 8px;
     padding-bottom: 10px;
     font-family: $Paragraph-font;
     font-weight: 700;
    }
   }
   .details-list {
    p {
     @include style-list;
    }
    .free-ssl-service {
     color: #03a903;
    }
   }
   .moretext {
    p {
     @include style-list;
    }

    .fa-question-circle {
     cursor: pointer;
     margin-left: 4px;
    }
   }
   .moretext p:last-child {
    border: none;
   }
  }
  .inputBillingcycle {
   .headerBillingcycle {
    @include style-headers;
    margin-top: 5px;
    padding: 10px 31px;
    h4 {
     @include style-text-header;
     font-size: 15px;
     .fa-receipt {
      @include style-icon-befor-header;
     }
    }
   }
   .new-addon {
    @include product-info;
    .order-details {
     .discount-main {
      text-align: center;
      margin-bottom: 23px;

      .old-price {
       margin: 0;
       strike {
        color: #5cb85c;
        font-weight: 600;
        font-size: 16px;
       }
      }
      .discount-tag {
       background-color: #06155a;
       text-align: center;
       .discount-size {
        p {
         margin: 0;
         color: #fff;
         font-size: 13px;
         font-weight: 600;
         padding: 5px 0 0 0;
        }
       }
      }
      .discount-tag::after {
       display: block;
       content: "";
       width: 0;
       border-top: solid #06155a 1rem;
       border-left: transparent solid 1rem;
       border-right: transparent solid 1rem;
       position: relative;
       top: 16px;
       left: 40%;
      }
     }
     .order-details-list {
      padding: 1.4rem 0.8rem;
      border: 1px solid #dcdcdc;
      border-radius: 5px;
      text-align: center;
      cursor: pointer !important;
      p {
       font-size: 14px;
       color: #666;
      }
      h1 {
       margin-bottom: 4px;
       font-size: 1.4655rem;
       font-family: "Roboto", sans-serif;
       font-weight: 600;
       line-height: 1.25;
       color: #1a202c;
      }
      span {
       font-size: 14px;
       font-weight: 700;
       color: #666;
      }
     }
    }
    .order-details-now {
     margin-top: 30%;
     background-color: map-get($branding, theme-color-main-magenta);
     border: none;
     border-radius: 5px;
     .order-details-list {
      p {
       color: #fff;
      }
      h1 {
       color: #fff;
      }
      span {
       color: #fff;
      }
     }
    }
   }
  }
  .PanelAddons {
   .headerPanelAddons {
    @include style-headers;

    h4 {
     @include style-text-header;
     font-size: 15px;
     .fa-check {
      @include style-icon-befor-header;
     }
    }
   }
   .container-section-panal-addon {
    .panel-body {
     display: flex;
     justify-content: center;
     margin: 10px 0;
     .radio-option {
      padding: 0 20px;
      label {
       display: flex;
       align-items: center;
       font-size: 14px;
       margin: 10px 0 3px 0;
       align-content: center;
       justify-content: center;
      }
      .img {
       display: flex;
       justify-content: center;
       align-items: center;
       img {
        width: 30%;
       }
      }
      p {
       margin-left: 7px;
       color: #666;
       text-align: center;
       margin: 7px 0 3px 0;
      }
      .radio-round {
       position: relative;
       background-position: -120px 0;
       display: inline-block;
       vertical-align: middle;
       margin: 0;
       padding: 3px 2px !important;
       width: 24px;
       height: 24px;
       background: #fff;
       border: 1px solid #4c4c4c;
       border-radius: 3px;
       cursor: pointer;
       border-radius: 11%;
       input {
        position: absolute;
        top: -20%;
        left: -20%;
        display: block;
        width: 140%;
        height: 140%;
        margin: 0px;
        padding: 0px;
        background: rgb(255, 255, 255);
        border: 0px;
        opacity: 0;
       }
       ins {
        position: absolute;
        top: -20%;
        left: -20%;
        display: block;
        width: 140%;
        height: 140%;
        margin: 0px;
        padding: 0px;
        background: rgb(255, 255, 255);
        border: 0px;
        opacity: 0;
       }
      }

      span {
       margin-left: 7px;
       color: #666;
      }
      .radio-round::before {
       content: "\2713";
       height: 16px;
       width: 16px;
       color: #fff;
       font-family: "Font Awesome 5 Pro";
       font-weight: 900;
       line-height: 1;
       font-size: 21px;
      }
     }
    }
    .panel-price {
     padding: 6px 10px;
     background-color: #e8e8e8;
     margin: 10px 0;
     border-radius: 3px;
     text-align: center;
     color: #666;
    }
    .panel-add {
     background-color: #06155a;
     color: #fff;
     padding: 6px 10px;
     background-color: #06155a;
     color: #fff;
     border-radius: 3px;
     text-align: center;
     cursor: pointer;
    }
   }
  }
 }
 .scrollingPanelContainer {
  .title-order-summary {
   @include style-headers;
   margin-top: 5px;
   padding: 10px 31px;
   h5 {
    @include style-text-header;
    font-size: 15px;
    .fa-globe {
     @include style-icon-befor-header;
    }
   }
  }
  .order-summary {
   @include product-info;
   padding-top: 35px;
   padding-bottom: 35px;
   .title {
    h4 {
     font-size: 18px;
     color: #06155a;
     padding-top: 8px;
     padding-bottom: 10px;
     font-family: "Montserrat", sans-serif !important;
     font-weight: 700;
     text-align: center;
    }
   }
  }
  .product-heading {
   display: flex;
   justify-content: space-between;
   span {
    @include text-in-span-summary;
   }
  }
  .summary-totals {
   .sectiion-list {
    display: flex;
    justify-content: space-between;
    span {
     @include text-in-span-summary;
    }
   }
  }
  .total-due-today {
   display: flex;
   justify-content: space-between;
   span {
    @include text-in-span-summary;
   }
  }
 }
 .btnDomainContinue {
  display: flex;
  justify-content: center;
  align-items: center;
  button {
   border: 1px solid map-get($branding, them-color-option-tow);
   box-shadow: 0 2px 4px -1px map-get($branding, them-color-option-tow);
   border-color: map-get($branding, them-color-option-tow);
   background-color: map-get($branding, them-color-option-tow);
   border-radius: 4px;
   padding: 9px 25px;
   font-family: $Numbers-font;
   font-weight: 400;
   font-size: 1rem;
   .link {
    color: map-get($color-texts, main-color);
    text-decoration: none;
   }
   .fa-arrow-circle-right {
    color: map-get($color-texts, main-color);
   }
  }
 }
}
</style>
